import { Group, Image, Stack } from '@mantine/core';
import { useTheme } from '@shared/theme';
import { FunctionComponent, PropsWithChildren } from 'react';

export const EmblaPageLayout: FunctionComponent<PropsWithChildren<{ noPadding?: boolean }>> = ({
  children,
  noPadding,
}) => {
  const { theme } = useTheme();

  return (
    <Stack px={0} h={'100%'}>
      <Group gap={0} align="flex-start" wrap="nowrap" h={'100%'}>
        <Stack px="md" flex={1} align="center" h={'100%'} gap={0}>
          <Group
            justify="center"
            w="100%"
            pt={'md'}
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1000,
              background: theme.other.theme.funnel.background,
            }}
          >
            <Image src={theme.other.theme.assets.Logo} h={46} />
          </Group>
          <Stack flex={1} pt={noPadding ? undefined : 'xl'} mx="xl" w={'100%'} h={'100%'}>
            {children}
          </Stack>
        </Stack>
      </Group>
    </Stack>
  );
};
